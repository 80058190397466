<!--
 * @Description: 车行道停车运营管理 运营管理 异常标记记录 abnormalMarkRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-03-16 10:48:24
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="订单编号">
              <el-input v-model="searchForm.orderSequence"
                        clearable
                        placeholder="请输入订单编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="操作人">
              <el-input v-model="searchForm.operator"
                        clearable
                        placeholder="请输入操作人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="操作类型"
                          prop="numberPlateColorCode">
              <el-select v-model="searchForm.operationType"
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="(item, index) in operateList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="8">
            <el-form-item label="操作时间"
                          label-width="90px">
              <el-date-picker v-model="operateTime"
                              type="datetimerange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topThirdLine">
          <el-col :span="6">
            <el-row class="topButton">
              <el-button @click="queryButton"
                         type="primary">查 询</el-button>
              <el-button @click="resetForm"
                         type="info"
                         style="margin-left:17px">重 置</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <!-- 表格 -->
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span> 异常标记记录 </span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">
          导 出
        </el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table ref="table"
                  :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="detailsClick(scope.row)"
                               type="text"
                               size="medium">订单详情</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 查看弹窗 -->
    <el-dialog title="订单详情"
               close-on-click-modal
               :visible.sync="watchListDialog"
               :append-to-body="true"
               :destroy-on-close="true"
               width="90%">
      <div style="display:flex">
        <el-row style="margin-top:25px;">
          <el-row>
            <span :span="8"
                  class="span-title">订单编号:</span>
            <span :span="16"
                  class="span-text">
              {{watchFormList.orderSequence}}</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span style="color:white;font-size:19px">{{watchFormList.plateNumber}}</span>
            <span style="color:white;font-size:17px;margin-left: 20px;">{{watchFormList.plateColor}}</span>
          </el-row>
          <el-row style="margin-top:30px">
            <span :span="24"
                  class="span-title">停车时长</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span style="font-size:30px;color:#08F7E5">
              {{watchFormList.parkDuration}}
            </span>
          </el-row>
          <el-row style="margin-top:30px">
            <span :span="8"
                  class="span-title">订单时间:</span>
            <span :span="16"
                  class="span-text">{{watchFormList.orderCreateTime}}</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span :span="8"
                  class="span-title">停车场名称:</span>
            <span :span="16"
                  class="span-text">{{watchFormList.parkName}}</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span :span="8"
                  class="span-title">泊位编号:</span>
            <span :span="16"
                  class="span-text">{{watchFormList.parkSpaceNumber}}</span>
          </el-row>
        </el-row>
        <!-- 右侧 -->
        <el-row style="margin-left:40px;display:flex;margin-bottom:10px;margin-top:10px">
          <!-- 入场 -->
          <el-row style="border:1px solid #0F91DE;height:390px;width:240px">
            <el-row style=" height:40px;line-height:40px;display:flex;  justify-content: space-around;">
              <el-row style="color:#6DE920">入场</el-row>
              <el-row style="color:white">{{watchFormList.entranceTime}}</el-row>
            </el-row>
            <el-row style=" height:350px">
              <el-image style="width:100%;height:100%;"
                        :src="$imgBaseUrl + pictureIn.evidencePic"
                        :preview-src-list="insrcList"
                        :fit="fit">
                <el-row slot="error"
                        style="height:100%;text-align:center">
                  <el-row style="height:100%;margin-top:145px;color:white">
                    <span v-if="pictureIn.evidencePic">加载失败</span>
                    <span v-else>该图片未上传</span>
                  </el-row>
                </el-row>
              </el-image>
            </el-row>
          </el-row>
          <!-- 出场 -->
          <el-row style=" margin-left: 35px;border: 1px solid rgb(15, 145, 222);height: 390px;width: 240px;">
            <el-row v-if="watchFormList.leaveTime==''||watchFormList.leaveTime==null"
                    style="height:40px;line-height:40px;display:flex;  justify-content: space-around;">
              <el-row style="color:#F98D0B">未出场</el-row>
            </el-row>
            <el-row v-else
                    style="height:40px;line-height:40px;display:flex;  justify-content: space-around;">
              <el-row style="color:#F98D0B">出场</el-row>
              <el-row style="color:white">{{watchFormList.leaveTime}}</el-row>
            </el-row>
            <el-row style="height:350px">
              <el-image style="width:100%;height:100%"
                        :src="$imgBaseUrl + pictureOut.evidencePic"
                        :preview-src-list="outsrcList"
                        :fit="fit">
                <el-row slot="error"
                        style="height:100%;text-align:center">
                  <el-row style="height:100%;margin-top:145px;color:white">
                    <span v-if="pictureOut.evidencePic">加载失败</span>
                    <span v-else>该图片未上传</span>
                  </el-row>
                </el-row>
              </el-image>
            </el-row>
          </el-row>
          <!-- 车牌最清晰 -->
          <el-row style="margin-left: 35px;border: 1px solid rgb(15, 145, 222);height: 390px;width: 240px;">
            <el-row style="height:40px;line-height:40px;display:flex;  justify-content: space-around;">
              <el-row style="color:#F98D0B">车牌最清晰</el-row>
            </el-row>
            <el-row style="height:350px">
              <el-image style="width:100%;height:100%"
                        :src="$imgBaseUrl + pictureClear.evidencePic"
                        :preview-src-list="clearsrcList"
                        :fit="fit">
                <el-row slot="error"
                        style="height:100%;text-align:center">
                  <el-row style="height:100%;margin-top:145px;color:white">
                    <span v-if="pictureClear.evidencePic">加载失败</span>
                    <span v-else>该图片未上传</span>
                  </el-row>
                </el-row>
              </el-image>
            </el-row>
          </el-row>
          <!-- 图片 -->
          <el-row style="margin-left: 35px;border: 1px solid rgb(15, 145, 222);height: 390px;width: 240px;">
            <el-row style="height:40px;line-height:40px;display:flex;  justify-content: space-around;">
              <el-row style="color:#F98D0B">异常图片</el-row>
            </el-row>
            <el-row style="height:350px">
              <el-image style="width:100%;height:100%"
                        :src="$imgBaseUrl + watchFormList.pictureUrl"
                        :preview-src-list="pictureUrlsrcList"
                        :fit="fit">
                <el-row slot="error"
                        style="height:100%;text-align:center">
                  <el-row style="height:100%;margin-top:145px;color:white">
                    <span v-if="watchFormList.pictureUrl">加载失败</span>
                    <span v-else>该图片未上传</span>
                  </el-row>
                </el-row>
              </el-image>
            </el-row>
          </el-row>
          <!-- 视频 -->
          <el-row style="margin-left: 35px;border: 1px solid rgb(15, 145, 222);height: 390px;width: 240px;">
            <el-row style="height:40px;line-height:40px;display:flex;  justify-content: space-around;">
              <el-row style="color:#F98D0B">异常视频</el-row>
            </el-row>
            <el-row style="height:350px;display: flex;justify-content: center;align-items: center;">
              <video v-if="watchFormList.videoUrl"
                     class="avatar"
                     width="100%"
                     height="100%"
                     controls="controls"
                     :src="$imgBaseUrl+ watchFormList.videoUrl"></video>
              <el-row v-else
                      style="height:100%;text-align:center">
                <el-row style="height:100%;margin-top:145px;color:white">该视频未上传</el-row>
              </el-row>
            </el-row>
          </el-row>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import formatDuration from '@/utils/duration'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      tableColumnList: [
        {
          prop: 'orderSequence',
          label: '订单编号',
        },
        {
          prop: 'operator',
          label: '操作员',
        },
        {
          prop: 'operatorName',
          label: '操作员名称',
        },
        {
          prop: 'operationTime',
          label: '操作时间',
        },
        {
          prop: 'operationTypeName',
          label: '操作类型',
        },
      ],
      tableList: { list: [] },
      listMap: { pageNum: 1, pageSize: 15 },

      searchForm: {},
      pageNum: 1,
      pageSize: 15,

      operateTime: [],// 操作时间
      operateList: [],// 操作类型

      watchListDialog: false, // 查看弹窗显示
      watchFormList: {}, // 查看弹窗绑定
      pictureIn: {},// 入场图片
      pictureOut: {}, // 出场图片
      pictureClear: {}, // 车牌最清晰图片
      insrcList: [],// 进场取证图片预览
      outsrcList: [],// 出场取证图片预览
      clearsrcList: [],// 车牌最清晰取证图片预览
      pictureUrlsrcList: [],// 取证图片预览
      fit: "fill",
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryOperateList()
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 点击详情
    detailsClick (row) {
      this.pictureIn = {}
      this.pictureOut = {}
      this.pictureClear = {}
      this.insrcList = []
      this.outsrcList = []
      this.clearsrcList = []
      this.pictureUrlsrcList = []
      this.watchFormList = row
      this.watchListDialog = true
      this.pictureUrlsrcList.push(this.$imgBaseUrl + this.watchFormList.pictureUrl)
      let info = {
        orderSequence: row.orderSequence
      }
      this.$abnormalMarkRecord.getMarkOrder(info).then(res => {
        res.resultEntity.parkDuration = formatDuration(res.resultEntity.parkDuration)
        this.pictureUrlsrcList.push(this.$imgBaseUrl + res.resultEntity.pictureUrl)
        this.watchFormList = res.resultEntity
        let that = this
        that.$queryDict.getEvidenceByOrderSequence(info).then(res => {
          if (res.resultEntity.length == 0) {
            that.$error
          }
          else if (res.resultEntity.length == 1) {
            that.pictureIn = res.resultEntity[0]
            that.insrcList.push(that.$imgBaseUrl + that.pictureIn.evidencePic)
          }
          else if (res.resultEntity.length == 2) {
            that.pictureIn = res.resultEntity[0]
            that.pictureClear = res.resultEntity[1]
            that.insrcList.push(that.$imgBaseUrl + that.pictureIn.evidencePic)
            that.clearsrcList.push(that.$imgBaseUrl + that.pictureClear.evidencePic)
          }
          else if (res.resultEntity.length == 3) {
            that.pictureIn = res.resultEntity[0]
            that.pictureClear = res.resultEntity[1]
            that.pictureOut = res.resultEntity[2]
            that.insrcList.push(that.$imgBaseUrl + that.pictureIn.evidencePic)
            that.clearsrcList.push(that.$imgBaseUrl + that.pictureClear.evidencePic)
            that.outsrcList.push(that.$imgBaseUrl + that.pictureOut.evidencePic)
          }
        })
      })
    },
    // 获取数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$abnormalMarkRecord.listByPageIn(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    queryButton () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.operateTime) {
        this.searchForm.operationStartTime = this.operateTime[0]
        this.searchForm.operationEndTime = this.operateTime[1]
      }
      else {
        this.searchForm.operationStartTime = ''
        this.searchForm.operationEndTime = ''
      }
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.searchForm = {}
      this.operateTime = []
      this.queryTableList()
    },
    // 导出
    exportFile () {
      this.$axios.post(this.$downloadOrderBaseUrl + "/orderOperationRecord/exportIn", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "异常标记记录.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 查看操作类型
    queryOperateList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '8233E3AF931D11ED8F8B4A065F57E9D4',
          },
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.operateList = res.resultEntity
      })
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    @include background('blockBg');
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.span-title {
  color: #79baf7;
  font-size: 16px;
}
.span-text {
  color: #789db7;
  font-size: 16px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>